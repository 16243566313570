import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image/withIEPolyfill";

import { media } from "utils/Media";

const HomeHeroStyles = styled.header`
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  height: 90vh;
  min-height: 600px;
  position: relative;
  .wrapper {
    .mobile {
      position: absolute;
      inset: 0;
      &::after {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        background: rgba(179, 158, 105, 0.5);
        z-index: 99;
        content: "";
      }
      .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
      }
      @media ${media.md} {
        display: none;
      }
    }
    .desktop {
      display: none;
      @media ${media.md} {
        display: block;
        position: absolute;
        inset: 0;
        &::after {
          position: absolute;
          inset: 0;
          width: 100%;
          height: 100%;
          background: rgba(179, 158, 105, 0);
          z-index: 99;
          content: "";
        }
        .gatsby-image-wrapper {
          width: 100%;
          height: 100%;
        }
      }
    }
    .content {
      width: 90%;
      height: 100vh;
      min-height: 600px;
      margin: 0 auto;
      display: flex;
      /* align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      text-align: left; */
      flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
      position: relative;
      z-index: 100;
      .name {
        color: #000;
        font-size: 2.5rem;
        font-weight: 300;
        text-transform: uppercase;
        line-height: 1.9rem;
        margin-bottom: 0;
        /* br {
          display: none;
        } */
        span {
          color: #000;
          text-transform: lowercase;
          padding-left: 5px;
          font-weight: 300;
        }
      }
      .role {
        color: #fdf8d3;
        font-size: 2.5rem;
        font-weight: 300;
        text-transform: uppercase;
        line-height: 1.9rem;
        span {
          color: #fdf8d3;
          text-transform: lowercase;
          padding-left: 5px;
          font-weight: 300;
        }
      }
      .date {
        color: #fdf8d3;
        font-size: 1.5rem;
        font-weight: 300;
        text-transform: uppercase;
        /* margin-left: -1rem; */
      }
    }
  }
  @media ${media.extraSmallMob} {
    height: 80vh;
    .wrapper {
      .content {
        height: 85vh;
        .name, .role {
          font-size: 2.85rem;
          line-height: 2.2rem;
          position: relative;
          span {
            position: absolute;
            bottom: 0;
          }
        }
      }
    }
  }
  @media ${media.extraLargeMob} {
    height: 60vh;
    .wrapper {
      .content {
        height: 69vh;
      }
    }
  }
  @media ${media.sm} {
    height: 55vh;
    min-height:  700px;
    .wrapper {
      .content {
        height: 60vh;
        min-height:  700px;
        .name,
        .role {
          font-size: 3rem;
          line-height: 2.5rem;
        }
        .name {
          margin-bottom: 0.5rem;
        }
      }
    }
  }
 
  @media ${media.md} {
    height: 55vh;
    .wrapper {
      .content {
        height: 45vh;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        min-height: 600px;
        .name,
        .role {
          position: relative;
          font-size: 3.5rem;
          line-height: 3rem;
          margin-bottom: 0;
          br {
            display: block;
          }
          span {
            font-size: .85rem;
            position: absolute;
            bottom: 2.5px;
          }
        }
        
      }
    }
  }
  @media ${media.xl} {
    height: 45vh;
    min-height: 500px;
    .wrapper {
      .content {
        height: 40vh;
        .name,
        .role {
          /* font-size: 4rem; */
          line-height: 4rem;
          font-size: 85px;
          margin-bottom: 0;
          span {
            font-size: 1rem;
          }
        }
      }
    }
  }
`;
const HomeHero = () => {
  const { bg, mobBg } = useStaticQuery(graphql`
    query ImageQuery {
      bg: file(relativePath: { eq: "new-hero-background.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      mobBg: file(relativePath: { eq: "new-hero-background.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <HomeHeroStyles>
      <section className="wrapper">
        <div className="mobile">
          <Img fluid={mobBg.childImageSharp.fluid} alt="" />
        </div>
        <div className="desktop">
          <Img fluid={bg.childImageSharp.fluid} alt="" />
        </div>
        <div className="content">
          <div className="left">
            <h3 className="name">
              Richard <br /> Coyle<span>is</span>
            </h3>
            <h3 className="role">
              Atticus <br />Finch<span>until</span>
            </h3>
            <h3 className="date">19 November</h3>
          </div>
          <div className="right mt-3 mt-lg-0">
            <h3 className="name">
              Matthew <br /> Modine<span>is</span>
            </h3>
            <h3 className="role">
              Atticus <br />Finch<span>beginning</span>
            </h3>
            <h3 className="date">21 November</h3>
          </div>
        </div>
      </section>
    </HomeHeroStyles>
  );
};

export default HomeHero;
