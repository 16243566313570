import React from "react";
import styled from "styled-components";
import Img from "gatsby-image/withIEPolyfill";
import { graphql, useStaticQuery } from "gatsby";

import { media } from "utils/Media";

const AwardsStyles = styled.section`
  width: 100%;
  .awards {
    width: 90%;
    margin: 0 auto;
    padding: 2rem 0 1rem;
    .copy {
      text-align: center;
      padding: 1rem 0;
      p {
        margin: 0;
        text-transform: uppercase;
        color: #fff8d1;
        font-family: clarendon_urw_narrowlight, Helvetica, Arial, sans-serif;
        font-weight: 300;
        font-size: 18px;
        padding: 0.05rem 0;
        @media ${media.xl} {
                font-size: 25px;
            }
      }
      span {
        display: none;
      }
      section {
        padding-top: 0.5rem;
        @media ${media.sm} {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          p {
            font-size: 18px;
          }
          span {
            color: #88939f;
            display: block;
          }
        }
        @media ${media.md} {
        p, span {
            font-size: 24px;
        }
        }
        @media ${media.lg} {
        p, span {
            font-size: 30px;
        }
        }
        @media ${media.xl} {
        p, span {
            font-size: 38px;
        }
        }
      }
    }
    @media ${media.md} {
        max-width: 650px;
    }
    @media ${media.lg} {
        max-width: 850px;
    }
    @media ${media.xl} {
        max-width: 1050px;
    }
  }
`;
const Awards = () => {
  const { img } = useStaticQuery(graphql`
    query {
      img: file(relativePath: { eq: "awards.png" }) {
        childImageSharp {
          fluid(maxWidth: 1300) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);
  return (
    <AwardsStyles>
      <div className="awards">
        <div className="img">
          <Img fadeIn={true} fluid={img.childImageSharp.fluid} />
          <h3 className="sr-only">Nominated for six Oliver Awards</h3>
        </div>
        <div className="copy">
          <p>Including</p>
          <section>
            <p style={{ color: "#B49E6D" }}>BEST NEW PLAY </p>
            <span>|</span>
            <p>BEST DIRECTOR</p>
            <span>|</span>
            <p style={{ color: "#B49E6D" }}>BEST SET DESIGN</p>
          </section>
        </div>
      </div>
    </AwardsStyles>
  );
};

export default Awards;
