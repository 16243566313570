import React, {Component} from "react"
import {graphql, navigate, StaticQuery} from 'gatsby';
import Layout from "components/Layout/Layout"
import {Container} from "reactstrap"
import styled from 'styled-components'
import {media} from "utils/Media"
import Footer from "components/Layout/Footer"
import HomeCarousel from "components/Carousel/HomeCarousel"
import PerformanceBar from "components/shared/PerformanceBar"
import HomeTitle from "../components/shared/HomeTitle"
import Img from "gatsby-image/withIEPolyfill";
import BillingLondon from "../images/London-billing-outline.svg"
import {window} from "browser-monads";
import addToMailchimp from "gatsby-plugin-mailchimp";
import Cookies from 'universal-cookie'
import HomeHero from "components/shared/HomeHero";
import { Banner, Awards } from "components/Blocks";

const cookies = new Cookies();
const COOKIE_DOMAIN = "tokillamockingbird.co.uk";
// const COOKIE_DOMAIN = "localhost";
let expiry = new Date();
expiry = new Date(expiry.setMonth(expiry.getMonth() + 3))


const CopyOnImage = styled.div`
  text-align: center;
  padding: 5rem 2rem 2.5rem;

  @media ${media.lg} {
    padding: 6rem 2rem 3rem;
  }

  h2 {
    color: #ff3430;
    margin: 0 auto 0.5rem;

    font-size: 9.6vw;
    line-height: 8.6vw;

    @media ${media.lg} {
      font-size: 5.2vw;
      line-height: 4.4vw;
    }
  }

  h3 {
    margin: 0 auto;
    font-size: 3.8vw;
    line-height: 4.2vw;
    color: #fff8d1;

    @media ${media.lg} {
      font-size: 1.3vw;
      line-height: 1.5vw;
    }
  }
`
const WelcomePage = styled.div`
  position: relative;
  width: 100%;
  // margin-top: 150px;
  //
  // @media ${media.sm} {
  //   margin-top: 200px;
  // }
  // @media ${media.lg} {
  //   margin-top: 200px;
  // }

  .mainTitle {
    text-align: left;
    margin-left: 1.6%;
    margin-bottom: 2rem;
    margin-top: 2rem;

    @media ${media.lg} {
      margin-left: 12%;
    }

    p {
      font-family: "clarendon_urw_narrowlight", Arial, sans-serif;
    }

    .leftIndent {
      padding-left: 15%;
      position: relative;

      @media ${media.lg} {
        padding-left: 13.1%;
      }

      &.last {
        //height: 2vw;
      }

      p {
        text-transform: uppercase;
        color: #fff8d1;
        margin: 0;
        font-size: 9.6vw;
        line-height: 8.6vw;

        @media ${media.lg} {
          font-size: 5.2vw;
          line-height: 4.4vw;
        }

        &.blue {
          color: #8693a0;
        }
      }

      .absolute {
        color: #b39d6b;
        font-size: 3.8vw;
        line-height: 3.8vw;

        @media ${media.lg} {
          position: absolute;
          left: 0;
          top: 0;
          font-size: 1.9vw;
          line-height: 150%;
        }
      }
    }

    h1 {
      text-transform: uppercase;
      color: #ff3430;
      margin: 0;
      font-size: 9.6vw;
      line-height: 8.7vw;
      font-family: "clarendon_urw_narrowlight", Arial, sans-serif;

      @media ${media.lg} {
        font-size: 5.2vw;
        line-height: 4.8vw;
      }
    }

    .smaller {
      padding-left: 5px;
      color: #b39d6b;
      text-transform: none;
      font-size: 3.8vw;

      @media ${media.lg} {
        font-size: 1.5vw;
      }
    }

    h2 {
      color: #b39d6b;
      text-transform: uppercase;
      margin: 0;
      font-size: 3.8vw;
      line-height: 4.2vw;

      @media ${media.lg} {
        font-size: 1.3vw;
        line-height: 1.5vw;
      }

      .smaller {
        color: #fff8d1;
        text-transform: uppercase;
        font-size: 3.8vw;
        line-height: 4.2vw;
        padding-left: 0;

        @media ${media.lg} {
          font-size: 1.3vw;
          line-height: 1.5vw;
        }
      }
    }
  }

  .billing {
    margin-left: 10.5%;
    @media ${media.lg} {
      max-width: 80vw;
    }
  }


  .yellow-bg {
    background-color: ${props => props.theme.colors.yellow};
  }

  .topImage {
    padding-top: 85px;
    position: relative;
    z-index: 1;

    @media ${media.md} {
      padding-top: 51px;
    }
  }

  #tickx-widget {
    margin: 3rem auto;
  }
`

class IndexPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            thankYou: false,
            showCalendar: false,
        }
    }

    componentDidMount() {
        if (!cookies.get('popup')) { // (cookies.get('euconsent') || cookies.get('eupubconsent')) &&
            // this.openSignupModal()

            cookies.set('popup', 'true', {
                expires: expiry,
                domain: COOKIE_DOMAIN
            });
        }

        this.handleScriptLoad();
    }

    openSignupModal = () => {
        if (typeof window.tx !== 'undefined') {
            window.tx({
                widgetId: 'TKaMBlondon',
                configId: '3b435da5',
                type: 'onboarding',
                onDismiss: (obj) => {
                    if (obj) {
                        const listFields = {
                            'NAME': obj.user.name,
                            'REASON': obj.selected.questions["What is your main reason for seeing ‘To Kill a Mockingbird’?"][0],
                            'GOWITH': obj.selected.questions["Who are you<br />planning to go with?"][0],
                            'MARKETING': typeof obj.selected.terms["I would like to opt-in to hear about productions from the producers of ‘To Kill a Mockingbird’"] !== 'undefined' ? "Yes" : "No"
                        }
                        // const listFields = {
                        //     'NAME': obj.user.name,
                        //     'REASON': obj.selected.questions.shows[0],
                        //     'GOWITH': obj.selected.questions.who_with[0],
                        //     'MARKETING': typeof obj.selected.terms.show !== 'undefined' ? "Yes" : "No"
                        // }


                        addToMailchimp(obj.user.email, listFields) // listFields are optional if you are only capturing the email address.
                            .then(data => {
                                console.log(data)
                                navigate(
                                    "/thank-you/",
                                    {
                                        state: {
                                            thankYou: true,
                                            name: obj.user.name
                                        },
                                    }
                                )
                            })
                    }
                },
                onFormComplete: (user) => {
                    if (typeof window.gtag !== 'undefined') {
                        window.gtag = window.gtag || [];
                        window.gtag('event', 'conversion', {'send_to': 'AW-699463522/Ei_hCILdvrEBEOLuw80C'});
                    }
                }
            })
        }
    }

    handleScriptLoad = () => {
        if (typeof window.tx !== 'undefined') {
            let widgetIdName = 'TKaMBlondon'

            let configId = 'Rx3mpw4q';

            window.tx({
                widgetId: widgetIdName,
                configId: configId,
                selector: "#tickx-widget"
            });

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'tickx',
                'TickXName': 'London_All_Dates_Homepage'
            });
        }
    }

    render() {
        return (
            <Layout title="Book tickets direct with the official box office." page="index">
                <Banner />
                {/*<Awards />*/}
                <WelcomePage>
                    <Img fadeIn={true} fluid={this.props.data.bg.childImageSharp.fluid}
                         className="topImage d-block pb-5 pb-md-0 mb-4 mb-md-0" alt=""/>
                    {/*<img src={BillingLondon} className="d-block billing" alt="Rhys Ifans is Atticus Finch in Harper Lee's To Kill A Mockingbird. A new play by Aaron Sorkin. Directed by Bartlett Sher." title="Rhys Ifans is Atticus Finch in Harper Lee's To Kill A Mockingbird. A new play by Aaron Sorkin. Directed by Bartlett Sher."/>*/}
                    <div className="mainTitle">
                        <div className="leftIndent">
                            <p>Harper Lee’s</p>
                        </div>
                        <div className="line-4">
                            <h1>To Kill <br className="d-inline-block d-lg-none"/>a Mockingbird</h1>
                        </div>
                        <div className="leftIndent">
                            <span className="absolute">A new play by</span>
                            <p>Aaron Sorkin</p>
                        </div>
                        <div className="leftIndent">
                            <span className="absolute">Directed by</span>
                            <p>Bartlett Sher</p>
                        </div>
                        <div className="leftIndent last">
                            <h2>Gielgud Theatre, London<span className="smaller d-none d-lg-inline"> | </span><br
                                className="d-inline-block d-lg-none"/><span className="smaller">A Delfont Mackintosh Theatre</span>
                            </h2>
                        </div>
                    </div>
                    <div className="yellow-bg d-none d-md-block">
                        <HomeCarousel/>
                    </div>
                    <PerformanceBar/>
                    <Container fluid={true}>
                        <Container>
                            <Footer/>
                        </Container>
                    </Container>
                </WelcomePage>
            </Layout>
        )
    }
}


export default (props) => (
    <StaticQuery
        query={graphql`
    query {
        bg: file(relativePath: { eq: "new-hero-background.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1300) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
    }
    `}
        render={data => (
            <IndexPage data={data}/>
        )}
    />
)