import React from 'react'
import styled from 'styled-components'

import {media} from 'utils/Media'

const BannerStyles = styled.section`
  text-align: center;
  padding: 1.5rem 0 .5rem 0;
  background: #b39d6b;

  p {
    font-size: 24px;
    line-height: 100%;
    span {
      font-size: 24px;
      font-weight: bold;
    }
    @media ${media.sm} {
      font-size: 32px;
      span {
        font-size: 32px;
      }
    }
    @media ${media.md} {
      font-size: 48px;
      span {
        font-size: 48px;
      }
    }
    @media ${media.lg} {
      font-size: 56px;
      span {
        padding-right: 10px;
        font-size: 56px;
      }
    }
    @media ${media.xl} {
      font-size: 64px;
      span {
        font-size: 64px;
      }
    }
  }


`
const Banner = () => {
  return (
    <BannerStyles>
      <div className="banner">
        <p><span>10 MARCH 2022 – 20 MAY 2023 </span><br/>THANK YOU LONDON</p>
      </div>
    </BannerStyles>
  )
}

export default Banner